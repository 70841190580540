import $ from 'jquery';
import 'slick-carousel';

function eventsSlider() {
	function initEventsSlider() {
		$('#spaces-mobile-slider').not('.slick-initialized').slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: false,
		});
	}

	$(document).ready(function () {
		initEventsSlider();
		$(window).on('resize', function () {
			initEventsSlider();
		});
	});
}

export default eventsSlider;
