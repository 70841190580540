const checkResponsive = () => {
	const responsiveSwitch = window.getComputedStyle(
		document.querySelector('#responsive-switch'),
		null
	).display;

	if (responsiveSwitch != 'none') {
		return true;
	} else {
		return false;
	}
};

export default checkResponsive;
