import $ from 'jquery';
import 'slick-carousel';

function hmSlider() {
	$(document).ready(function () {
		$('.hm-slider-init').slick({
			infinite: true,
			autoplay: true,
			autoplaySpeed: 3000,
			slidesToShow: 4,
			slidesToScroll: 1,
			prevArrow: $('.hm-slider .slider-arrow_left'),
			nextArrow: $('.hm-slider .slider-arrow_right'),

			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 450,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});
	});
}

export default hmSlider;
