import $ from 'jquery';

function addMember() {
	$(document).ready(function () {
		$('.add_member_toggle').click(function () {
			$('.family_member').toggleClass('hidden');
		});
	});
}

export default addMember;
