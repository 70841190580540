function lightboxInit() {
	const lightbox = document.getElementById('lightbox');
	const lightboxWrapper = document.querySelector('.lightbox-wrapper');

	if (lightbox) {
		const spaces = document.getElementById('spaces');
		const spacesMobile = document.getElementById('spaces-mobile');
		const cards = spaces.querySelectorAll('.card');
		const cardsMobile = spacesMobile.querySelectorAll('.card');
		const lightboxCards = lightbox.querySelectorAll('.card');
		const closing = lightbox.querySelectorAll('.close');

		closing.forEach((button) => {
			button.addEventListener('click', function () {
				lightbox.style.display = 'none';

				lightboxCards.forEach((card) => {
					card.style.display = 'none';
				});
			});
		});

		lightboxWrapper.addEventListener('click', function () {
			lightbox.style.display = 'none';

			lightboxCards.forEach((card) => {
				card.style.display = 'none';
			});
		});

		cards.forEach((card, i) => {
			card
				.querySelector('.lightbox-switch')
				.addEventListener('click', function () {
					lightbox.style.display = 'flex';
					document.getElementById(`${card.id}-lightbox`).style.display = 'flex';
				});
		});

		cardsMobile.forEach((card, i) => {
			card
				.querySelector('.lightbox-switch')
				.addEventListener('click', function () {
					let cardId = this.id.replace('button', '');
					lightbox.style.display = 'flex';

					document.getElementById(`${cardId}lightbox`).style.display = 'flex';
				});
		});
	}
}

export default lightboxInit;
