import $ from 'jquery';
import 'slick-carousel';

function flexible5050ContentSlide() {
	$(document).ready(function () {
		$('.content-slide-5050').each(function () {
			$(this)
				.find('.imagewrap')
				.slick({
					asNavFor: $(this).find('.slides'),
					fade: true,
					draggable: false,
					dots: false,
					arrows: false,
				});

			$(this)
				.find('.slides')
				.slick({
					asNavFor: $(this).find('.imagewrap'),
					autoplay: true,
					autoplaySpeed: 4000,
					draggable: false,
					fade: true,
					prevArrow: $(this).find('[data-controls-left]'),
					nextArrow: $(this).find('[data-controls-right]'),
				});
		});
	});
}

export default flexible5050ContentSlide;
