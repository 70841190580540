import $ from 'jquery';
import 'slick-carousel';

function flexiblePartnerSlider() {
	$(document).ready(function () {
		$('.partner-slider').each(function (index) {
			$(this).slick({
				infinite: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,

				prevArrow: $('.partners .slider-arrow_left')[index],
				nextArrow: $('.partners .slider-arrow_right')[index],

				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
						},
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
						},
					},
				],
			});
		});
	});
}

export default flexiblePartnerSlider;
