function general($) {
	$(document).ready(function () {
		// Burger Menu

		var header = $('#header'),
			navButton = $('#header .menu-btn'),
			navMenu = $('#primary-navigation');

		navButton.click(function () {
			header.toggleClass('open');
		});

		function scrollShow() {
			$('[data-scroll-show]').each(function () {
				var slideInAt =
					window.pageYOffset + window.innerHeight - this.offsetHeight / 2;

				var imageBottom = this.offsetTop + this.offsetHeight;
				var isHalfShown = slideInAt > this.offsetTop;
				var isNotScrolledPast = window.pageYOffset < imageBottom;

				if (isHalfShown && isNotScrolledPast) {
					this.classList.add('show');
				} else {
					this.classList.remove('show');
				}
			});
		}

		var cachedWidth = $(window).width();

		$(window).resize(function () {
			var newWidth = $(window).width();

			if (newWidth !== cachedWidth) {
				header.removeClass('open');

				cachedWidth = newWidth;
			}
		});

		$(window).on('resize', function () {
			scrollShow();
		});

		$(window).scroll(function () {
			scrollShow();
		});

		scrollShow();

		// Animate the Logo on Scroll

		$(window).scroll(function () {
			if (this.pageYOffset < 100) {
				$('#logowrap').removeClass('active');
			} else {
				$('#logowrap').addClass('active');
			}
		});
	});
}

export default general;
