import $ from 'jquery';
import Swiper from 'swiper';
import 'slick-carousel';

function flexibleMediaGallery() {
	$(document).ready(function () {
		var mySwiper = new Swiper('.swiper-container', {
			effect: 'coverflow',
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: 'auto',
			loop: true,

			coverflowEffect: {
				rotate: 0,
				stretch: 300,
				depth: 200,
				modifier: 1,
				slideShadows: true,
			},

			navigation: {
				nextEl: '.slider-arrow_right',
				prevEl: '.slider-arrow_left',
			},
		});

		$('.slick-wrapper').slick({
			centerMode: true,
			centerPadding: '15%',
			arrows: false,
			dots: true,

			responsive: [
				{
					breakpoint: 800,
					settings: {
						centerPadding: '20%',
					},
				},
			],
		});
	});
}

export default flexibleMediaGallery;
