import $ from 'jquery';

function shop() {
	$(document).ready(function () {
		$(document).ready(function () {
			$('.product-filter-selected-after').on('click', function (e) {
				// $(`select[name=${$(this).data('name')}]`).val("")
				$(`select[name=${$(this).data('name')}]`).attr('disabled', true);
				$('#shop-filter-form').submit();
			});

			$('.page-numbers.dots').html('');

			$(document.body).trigger('wc_fragment_refresh');
		});
	});
}

export default shop;
