import $ from 'jquery';

function honouredMembers() {
	window.Site = window.Site || {};

	Site.Members = $.extend(
		{
			//Interval Container
			intval: {},

			//History Pages
			pages: [],

			//Parameters
			params: {
				search: null,

				induction: null,

				sport: null,

				category: null,

				//Current Page
				paged: Site.paged,
			},

			//The Page URL
			url: (function () {
				if ($('link[rel="canonical"]').length > 0) {
					return $('link[rel="canonical"]')
						.attr('href')
						.replace(/[\/]+$/, '');
				}

				return '';
			})(),

			/**
			 *
			 *	getParams
			 *		- Load the Params from a String
			 *
			 *	Params:
			 * 		- key: 		(String) The Param to Capture
			 * 		- string: 	(String) The URL String to lookup
			 *
			 **/
			getParam: function (key, string) {
				return (string.match(new RegExp(key + '=(.*?)(&|$)')) || ['', ''])[1];
			},

			//Animate
			preloader: {
				/**
				 *
				 *	preloader.start
				 * 		- Fadeout the Content and Fade in the Preloader
				 *
				 *	Params:
				 * 		callback: 	(function) The Callback Function
				 *
				 **/
				start: function (callback) {
					$('[data-member-list-container],[data-member-pagination-container]')
						.stop(true, true)
						.fadeTo(500, 0);

					$('#preloader').stop(true, true).fadeIn(500, callback);
				},

				/**
				 *
				 *	preloader.stop
				 * 		- Fadeout the Preloader and Fade in the Content
				 *
				 *	Params:
				 * 		callback: 	(function) The Callback Function
				 *
				 **/
				stop: function (callback) {
					$('[data-member-list-container],[data-member-pagination-container]')
						.stop(true, true)
						.fadeTo(500, 1);

					$('#preloader').stop(true, true).fadeOut(500, callback);
				},
			},

			/**
			 *
			 *	timeout
			 *		- Delay Callback until Timeout
			 *
			 * 	Params:
			 *		ns: 		(String) The Timeout Namespace
			 * 		callback: 	(Function) The Callback Function
			 * 		time: 		(Integer) The timeout (in ms)
			 **/
			timeout: function (ns, callback, time) {
				var obj = this;

				if (typeof obj.intval[ns] === 'undefined') obj.intval[ns] = null;

				if (obj.intval) {
					window.clearTimeout(obj.intval);
				}

				obj.intval = window.setTimeout(function () {
					callback();

					window.clearTimeout(obj.intval);
				}, time);
			},

			/**
			 *
			 *	load
			 *		- Loads the Updated values from WP
			 *
			 *	Params:
			 *
			 *
			 **/
			load: function () {
				var obj = Site.Members,
					data = {
						search: $('#search').val(),

						induction: $('#induction').val(),

						sport: $('#sport').val(),

						category: $('#category').val(),

						paged: Site.paged,
					};

				obj.preloader.start(function () {
					$.get(
						Site.ajax_url,
						$.extend({}, obj.params, data, { action: 'honored_members' }),
						function (response) {
							$.extend(
								{
									members: '',
									pagination: '',
								},
								response
							);

							if (response.members == '') {
								response.members = $('#no-entries').html();
							}

							$('[data-member-list-container]').html(response.members);

							$('[data-member-pagination-container]').html(response.pagination);

							$('[data-member-pagination-container] a[href]').each(function () {
								$(this).attr(
									'href',
									Site.Members.url +
										'/page/' +
										$(this)
											.attr('href')
											.match(/paged=([0-9]+)/)[1]
								);
							});

							if (JSON.stringify(data) != JSON.stringify(obj.params)) {
								var page =
									Site.Members.url +
									'/page/' +
									data.paged +
									'/?search=' +
									data.search +
									'&induction=' +
									data.induction +
									'&sport=' +
									data.sport +
									'&category=' +
									data.category;

								window.history.pushState(null, null, page);

								Site.Members.pages.push(page);
							}

							obj.preloader.stop();
						},
						'json'
					);
				});
			},

			/**
			 *
			 *	init
			 *		- Initialize the Page
			 *
			 *	Params:
			 * 		n/a
			 *
			 **/
			init: function () {
				this.params.search = this.getParam('search', window.location.href);
				this.params.induction = this.getParam(
					'induction',
					window.location.href
				);
				this.params.sport = this.getParam('sport', window.location.href);
				this.params.category = this.getParam('category', window.location.href);

				return this;
			},
		},
		{}
	).init();

	/**
	 *
	 *	KEYUP: #search
	 *		- Load the Updated Page Content
	 *
	 * 	Params:
	 * 		n/a
	 *
	 **/
	$('#search').keyup(function () {
		Site.Members.timeout(
			'filter',
			function () {
				Site.paged = 1;

				Site.Members.load();
			},
			500
		);
	});

	/**
	 *
	 *	CHANGE: #induction, #sport, #category
	 *		- Load the Updated Page Content
	 *
	 * 	Params:
	 * 		n/a
	 *
	 **/
	$('#induction, #sport, #category').change(function () {
		Site.paged = 1;

		Site.Members.timeout('filter', Site.Members.load, 500);
	});

	/**
	 *
	 *	CHANGE: #induction, #sport, #category
	 *		- Load the Updated Page Content
	 *
	 * 	Params:
	 * 		n/a
	 *
	 **/
	$('#member-pagination').on('click', '.page-numbers', function (e) {
		e.preventDefault();

		Site.paged = $(this)
			.attr('href')
			.match(/paged?(=|\/)([0-9]+)/)[2];

		Site.Members.load();
	});

	/**
	 *
	 *	ON: popstate
	 *		- Catch the "Back Button" event
	 *
	 * 	Params:
	 * 		n/a
	 *
	 **/
	$(window).on('popstate', function (event) {
		var location = event.target.location;

		if (
			location.pathname.indexOf('honoured-members') > -1 &&
			Site.Members.pages.length > 0
		) {
			Site.Members.pages.pop();

			Site.Members.params.search = Site.Members.getParam(
				'search',
				location.href
			);
			Site.Members.params.induction = Site.Members.getParam(
				'induction',
				location.href
			);
			Site.Members.params.sport = Site.Members.getParam('sport', location.href);
			Site.Members.params.category = Site.Members.getParam(
				'category',
				location.href
			);

			Site.paged = (location.href.match(/paged?(=|\/)([0-9]+)/) || [
				'',
				'',
				1,
			])[2];

			Site.Members.load();
		}
	});
}

export default honouredMembers;
