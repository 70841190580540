function popup() {
	document.addEventListener('DOMContentLoaded', function () {
		const popup = document.getElementById('simple-popup');
		const closeBtn = document.querySelector('.close-btn');
		const openBtn = document.querySelectorAll('.open-btn');
		const popupKey = 'popupDismissedAt'; // Key to store the dismissal time
		const popupDelay = 1000; // 3 seconds before showing popup
		const reappearAfter = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
		const body = document.querySelector('body');

		// Set this to true during development to disable the localStorage logic
		const inDevelopment = false; // Change to false for production

		// Check if the popup has been dismissed and when
		const popupDismissedAt = localStorage.getItem(popupKey);
		const now = new Date().getTime();

		if (popup) {
			if (body.classList.contains('home')) {
				// If in development, always show the popup
				if (
					inDevelopment ||
					!popupDismissedAt ||
					now - popupDismissedAt > reappearAfter
				) {
					setTimeout(function () {
						popup.style.display = 'flex';
						popup.classList.add('active');
					}, popupDelay);
				}
			}

			// Close popup and store dismissal time in localStorage
			closeBtn.addEventListener('click', function () {
				popup.style.display = 'none';
				popup.classList.remove('active');
				if (!inDevelopment) {
					localStorage.setItem(popupKey, new Date().getTime());
				}
			});

			// Open popup
			if (openBtn.length) {
				openBtn.forEach((btn) => {
					btn.addEventListener('click', function (e) {
						e.preventDefault();
						popup.style.display = 'flex';
						popup.classList.add('active');
					});
				});
			}

			// Close the popup when clicking outside the popup content
			window.addEventListener('click', function (e) {
				if (e.target === popup) {
					popup.style.display = 'none';
					popup.classList.remove('active');
					if (!inDevelopment) {
						localStorage.setItem(popupKey, new Date().getTime());
					}
				}
			});
		}
	});
}

export default popup;
