import $ from 'jquery';
import 'slick-carousel';

function flexibleTabsCards() {
	document.addEventListener('DOMContentLoaded', function () {
		const tabComponents = document.querySelectorAll(
			'.flexible-tabs_cards.desktop-only'
		);

		tabComponents.forEach((component) => {
			const tabs = component.querySelectorAll('.tab');
			const tabContents = component.querySelectorAll('.tab-content');
			let currentIndex = 0;

			function activateTab(index) {
				tabs.forEach((t) => t.classList.remove('active'));
				tabContents.forEach((tc) => tc.classList.remove('active'));
				tabs[index].classList.add('active');
				tabContents[index].classList.add('active');
			}

			component.querySelector('.left-arrow')?.addEventListener('click', () => {
				currentIndex = currentIndex === 0 ? tabs.length - 1 : currentIndex - 1;
				activateTab(currentIndex);
			});

			component.querySelector('.right-arrow')?.addEventListener('click', () => {
				currentIndex = currentIndex === tabs.length - 1 ? 0 : currentIndex + 1;
				activateTab(currentIndex);
			});

			tabs.forEach((tab, index) => {
				tab.addEventListener('click', () => {
					currentIndex = index;
					activateTab(index);
				});
			});

			// Activate the first tab by default
			if (tabs.length > 0) {
				activateTab(0);
			}
		});
	});

	// Flexible Slider
	$(document).ready(function () {
		const sliderComponents = document.querySelectorAll(
			'.flexible-tabs_cards-mobile-slider'
		);

		function initFlexibleSlider(component) {
			$(component).not('.slick-initialized').slick({
				infinite: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				arrows: false,
			});
		}

		sliderComponents.forEach((component) => {
			initFlexibleSlider(component);
			$(window).on('resize', function () {
				initFlexibleSlider(component);
			});
		});
	});
}

export default flexibleTabsCards;
