import $ from 'jquery';
import 'slick-carousel';

function donorSlider() {
	$(document).ready(function () {
		$('.donor-slider-init').slick({
			infinite: true,
			autoplay: false,
			autoplaySpeed: 3000,
			slidesToShow: 2,
			slidesToScroll: 1,
			prevArrow: $('.donor-slider .slider-arrow_left'),
			nextArrow: $('.donor-slider .slider-arrow_right'),

			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});

		$('#donationAmount').each(function () {
			$(this)
				.prop('Counter', 0)
				.animate(
					{
						Counter: $(this).text(),
					},
					{
						duration: 2500,
						easing: 'swing',
						step: function (now) {
							$(this).text(Math.ceil(now).toLocaleString('en'));
						},
					}
				);
		});
	});
}

export default donorSlider;
