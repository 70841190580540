import $ from 'jquery';

function slickSliderGallery() {
	$(document).ready(function () {
		if ($('.carousel').length) {
			$('.carousel').swipe({
				swipe: function (
					event,
					direction,
					distance,
					duration,
					fingerCount,
					fingerData
				) {
					if (direction == 'left') $(this).carousel('next');
					if (direction == 'right') $(this).carousel('prev');
				},
				allowPageScroll: 'vertical',
			});
		}

		// Desktop Image Gallery
		$('a.product-thumbnail').click(function () {
			var src = $(this).find('img').attr('src');
			$('#productImage').attr('src', src);
		});

		// Full width image gallery toggle
		$('.stretch-icon').click(function () {
			$('.product-gallery-section').show();
			$('.product-hero-section').hide();
			$('.product-additional-detail-container').hide();
		});

		$('.close-gallery').click(function () {
			$('.product-gallery-section').hide();
			$('.product-hero-section').show();
			$('.product-additional-detail-container').show();
		});

		// Toggle Product Gallery between mobile and desktop
		(function () {
			if ($(window).width() < 1024) {
				$('#productCarousel').show();
				$('.product-gallery').hide();
				$('.product-gallery-section').hide();
				$('.product-hero-section').show();
				$('.product-additional-detail-container').show();
			} else {
				$('#productCarousel').hide();
				$('.product-gallery').show();
			}

			window.addEventListener('resize', function () {
				if ($(window).width() < 1024) {
					$('#productCarousel').show();
					$('.product-gallery').hide();
					$('.product-gallery-section').hide();
					$('.product-hero-section').show();
					$('.product-additional-detail-container').show();
				} else {
					$('#productCarousel').hide();
					$('.product-gallery').show();
				}
			});
		})();
	});
}

export default slickSliderGallery;
