import $ from 'jquery';

function donateButton() {
	$(document).ready(function () {
		$('.donate-button').click(function () {
			var frame = document.getElementById('chimp-form-main');
			var content = frame.contentWindow;

			content.postMessage('open-chimp-frame', '*');

			frame.style.opacity = 1;
			frame.style.display = 'block';
		});
	});
}

export default donateButton;
