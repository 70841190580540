const wcDonations = () => {
	document.addEventListener('DOMContentLoaded', function () {
		const otherInput = document.querySelector('.price-wrapper input'); // Replace with actual ID or class

		if (otherInput) {
			otherInput.placeholder = 'Ex - 3000';
		}
	});
};

export default wcDonations;
