import $ from 'jquery';

function mobileNav() {
	$(document).ready(function () {
		$('.mobile-nav .has-submenu a').each(function (index) {
			if ($(this).hasClass('open')) {
				// Let accordion remain open
			} else {
				$(this).next('.sub-menu').hide();
			}
		});

		$('.mobile-nav .has-submenu').on('click', 'a', function () {
			$('.mobile-nav .has-submenu a').each(function (index) {
				if ($(this).hasClass('open')) {
					var $tab = $(this),
						$content = $tab.next('.sub-menu');

					$tab.toggleClass('open');
					$content.slideToggle();
				}
			});

			var $tab = $(this),
				$content = $tab.next('.sub-menu');

			$tab.toggleClass('open');
			$content.slideToggle();
		});
	});
}

export default mobileNav;
