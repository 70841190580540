import $ from 'jquery';
import 'slick-carousel';

function exhibitListingSlider() {
	$(document).ready(function () {
		function initSlider() {
			$('.slider-wrap')
				.not('.slick-initialized')
				.slick({
					infinite: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					prevArrow:
						"<button class='slider-arrow slider-arrow_left bgsea grid-1' aria-label='Previous'> <i class='fa fa-chevron-left' aria-hidden='true'></i> </button>",
					nextArrow:
						"<button class='slider-arrow slider-arrow_right bgsea grid-1' aria-label='Next'> <i class='fa fa-chevron-right' aria-hidden='true'></i> </button>",
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								arrows: false,
								dots: true,
							},
						},
						{
							breakpoint: 768,
							settings: 'unslick',
						},
					],
				});
		}
		initSlider();

		$(window).on('resize', initSlider);
	});
}

export default exhibitListingSlider;
