import $ from 'jquery';
import 'slick-carousel';

function flexibleHomepageHero() {
	$(document).ready(function () {
		$('.hero-slider.slide-wrapper').slick({
			asNavFor: '.small-slider',
			autoplay: false,
			autoplaySpeed: 4000,
			prevArrow: $('.homepage-hero .slider-arrow_left'),
			nextArrow: $('.homepage-hero .slider-arrow_right'),
			dots: true,
			appendDots: $('.dot-holder'),
			customPaging: function (slider, i) {
				var label = i + 1;
				return '<button><span>0' + label + '</span></button>';
			},
		});

		$('.small-slider.slide-wrapper').slick({
			asNavFor: '.hero-slider',
			fade: true,
			draggable: false,
			adaptiveHeight: true,
			dots: false,
			arrows: false,
		});
	});
}

export default flexibleHomepageHero;
